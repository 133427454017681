
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';
import { getAdvertisement } from '@/services/api';

import { useCreateQuestion, useUpdateQuestion } from '@/composables/api';
import { PartialQuestion, Question } from '@/interfaces/Question';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialQuestion & Partial<Question> = {
  question: '',
  answer: ''
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const advertisementsId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref(DEFAULT_FORM_VALUES);
    const { isLoading: isCreatedLoading, mutate: create } = useCreateQuestion();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateQuestion();

    onMounted(async() => {
      if (isEdit.value) {
        const res = await getAdvertisement({ advertisementsId });
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        formValues.value = res.data;
      }
    });

    return {
      formValues,
      formRef,
      isCreatedLoading,
      isUpdatedLoading
    };
  }
});
